.sidebar {
  flex: 1;
  min-height: 100vh;
  // background-color: #dd2f6e;
  // background-color: #156ab5d1;
  // background-color: rgb(220, 112, 132);
  background-color: white;
  // background-image: url("../../Assets/quizbz.png");
  // background-repeat: no-repeat;
  // background-position: center;

  .top {

    display: flex;
    padding: 10px 0;
    align-items: center;
    flex-direction: column;
    gap:10px;
    justify-content: center;
    // border-bottom: 0.4px solid white;
    // background-color: red;
    .logo {
     display: block;
 
     display: flex;
     align-items: center;
     justify-content: center;
    //  border: 1px solid;
     img{
       width: 70%;
     }
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    padding-left: 10px;
    padding-top: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        height: 40px;
        transition: 0.5s all linear;
        margin-right: 10px;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        align-items: center;
        padding: 5px 20px;
        margin-bottom: 10px;
       
        // width: 82%;
        color: #8A8A8A;
        cursor: pointer;

        &:hover {
          background-color: #FEF5F0;
          span {
            color: #F89F90;
          }
          .icon {
            color: #F89F90;
          }
        }

        .icon {
          font-size: 18px;
          color: #dd2f6e;
        }

        span {
          font-size: 13px;
          font-weight: 600;
          color: #8A8A8A;
     
          margin-left: 10px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin: 5px;
      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}

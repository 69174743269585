.home {
  display: flex;
  .homeContainer {
    flex: 6;
    .content{
      // padding: 20px;
      .center{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
      }
      .table_wrapper{
        background-color: white;
        padding: 30px 20px;
        border-radius: 9px;
          table{
            width: 100%;
            thead{
              tr{
                background-color: rgb(212, 200, 200);
              }
            }
          
            tr{
              // border: 1px solid #F5F5F5;
              &:nth-child(even){
                background-color: #f5f5f598;
              }
              // background-color: #F5F5F5;
              th,td{
                text-align: center;
                // border: 1px solid;
                padding: 15px;
                .link{
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-decoration: none;
                  background-color:#FA5F43;
                  color: white;
                  padding: 7px;
                  cursor: pointer;
                  height: 30px;
                  width:fit-content;
                  border-radius: 9px;
                }
              }
              td{
                padding: 10px;
                
              }
              
              
            }
          }
      }

    }
  }
}
